import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';

const BooleanButton = ({
  id,
  title,
  isOk,
  disabled,
  handleOnClick,
  disabledTooltipText,
}) => {
  const getButtonColor = () => {
    if (isOk) {
      return 'primary.main';
    }
    if (disabled) {
      return 'text.disabled';
    }
    return 'text.primary';
  };
  return (
    <Tooltip
      title={disabledTooltipText}
      arrow
      disableHoverListener={!disabled}
    >
      <span>
        <Button
          key={id}
          size="small"
          id={id}
          sx={{ px: 0, width: 90, overflowWrap: 'break-word' }}
          onClick={handleOnClick}
          disabled={disabled}
        >
          <Stack
            alignItems="center"
            direction="column"
            color={getButtonColor()}
          >
            {isOk
              ? (<CheckCircle fontSize="small" />)
              : (<Cancel fontSize="small" />)}
            <Typography>
              {title}
            </Typography>
          </Stack>
        </Button>
      </span>
    </Tooltip>
  );
};

BooleanButton.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isOk: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledTooltipText: PropTypes.string,
  handleOnClick: PropTypes.func.isRequired,
};

BooleanButton.defaultProps = {
  disabled: false,
  disabledTooltipText: '',
  isOk: null,
};

export default BooleanButton;
